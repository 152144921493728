<template>
  <div>
    <!-- <v-row>
      <v-col cols="12">
        <retained-by-week></retained-by-week>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col sm="12" md="8">
        <v-card>
          <v-toolbar flat dense>
            <span class="text-subtitle-1">Pre-lit Inventory</span>
            <v-spacer></v-spacer>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-switch
              v-model="showActiveOnly"
              label="Active Only"
              dense
              hide-details
              class="mt-0 pt-0"
            ></v-switch>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-btn-toggle v-model="viewMode" mandatory dense class="mt-0 pt-0">
              <v-btn small value="chart" title="Chart View">
                <v-icon small>mdi-chart-bar</v-icon>
              </v-btn>
              <v-btn small value="table" title="Table View">
                <v-icon small>mdi-table</v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-toolbar>
          <div class="chart-vis" v-if="viewMode === 'chart'">
            <inventory-by-user :options="inventoryByPreLitAttorney" />
          </div>
          <div v-else>
            <v-data-table
              :headers="getTableHeaders(inventoryByPreLitAttorney)"
              :items="getTableItems(inventoryByPreLitAttorney)"
              :sort-by="['total']"
              :sort-desc="[true]"
              dense
              :footer-props="{
                'items-per-page-options': [10, 25, -1],
              }"
            ></v-data-table>
          </div>
        </v-card>
      </v-col>
      <v-col sm="12" md="4">
        <v-card>
          <v-toolbar flat dense>
            <span class="text-subtitle-1">Lit Inventory</span>
            <v-spacer></v-spacer>
            <v-switch
              v-model="showActiveOnly"
              label="Active Only"
              dense
              hide-details
              class="mt-0 pt-0"
            ></v-switch>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-btn-toggle v-model="viewMode" mandatory dense class="mt-0 pt-0">
              <v-btn small value="chart" title="Chart View">
                <v-icon small>mdi-chart-bar</v-icon>
              </v-btn>
              <v-btn small value="table" title="Table View">
                <v-icon small>mdi-table</v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-toolbar>
          <div class="chart-vis" v-if="viewMode === 'chart'">
            <inventory-by-user :options="inventoryByLitAttorney" />
          </div>
          <div v-else>
            <v-data-table
              :headers="getTableHeaders(inventoryByLitAttorney)"
              :items="getTableItems(inventoryByLitAttorney)"
              :sort-by="['total']"
              :sort-desc="[true]"
              dense
              :footer-props="{
                'items-per-page-options': [10, 25, -1],
              }"
            ></v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" md="8">
        <v-card>
          <v-toolbar flat dense>
            <span class="text-subtitle-1">Pre-Lit Inventory Ranks</span>
            <v-spacer></v-spacer>
            <v-switch
              v-model="showActiveOnly"
              label="Active Only"
              dense
              hide-details
              class="mt-0 pt-0"
            ></v-switch>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-btn-toggle
              v-model="rankViewMode"
              mandatory
              dense
              class="mt-0 pt-0"
            >
              <v-btn small value="chart" title="Chart View">
                <v-icon small>mdi-chart-bar</v-icon>
              </v-btn>
              <v-btn small value="table" title="Table View">
                <v-icon small>mdi-table</v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-toolbar>
          <div v-if="rankViewMode === 'chart'">
            <highcharts ref="rankChart" :options="casesByRank" />
          </div>
          <div class="chart-vis" v-if="rankViewMode === 'chart'">
            <inventory-by-user :options="casesByRankByPreLitAttorney" />
          </div>
          <div v-else>
            <v-data-table
              :headers="getRankTableHeaders(casesByRankByPreLitAttorney)"
              :items="getRankTableItems(casesByRankByPreLitAttorney)"
              :sort-by="['total']"
              :sort-desc="[true]"
              dense
              :footer-props="{
                'items-per-page-options': [10, 25, -1],
              }"
            ></v-data-table>
          </div>
        </v-card>
      </v-col>
      <v-col sm="12" md="4">
        <v-card>
          <v-toolbar flat dense>
            <span class="text-subtitle-1">Lit Inventory Ranks</span>
            <v-spacer></v-spacer>
            <v-switch
              v-model="showActiveOnly"
              label="Active Only"
              dense
              hide-details
              class="mt-0 pt-0"
            ></v-switch>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-btn-toggle
              v-model="rankViewMode"
              mandatory
              dense
              class="mt-0 pt-0"
            >
              <v-btn small value="chart" title="Chart View">
                <v-icon small>mdi-chart-bar</v-icon>
              </v-btn>
              <v-btn small value="table" title="Table View">
                <v-icon small>mdi-table</v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-toolbar>
          <div class="chart-vis" v-if="rankViewMode === 'chart'">
            <inventory-by-user :options="casesByRankByLitAttorney" />
          </div>
          <div v-else>
            <v-data-table
              :headers="getRankTableHeaders(casesByRankByLitAttorney)"
              :items="getRankTableItems(casesByRankByLitAttorney)"
              :sort-by="['total']"
              :sort-desc="[true]"
              dense
              :footer-props="{
                'items-per-page-options': [10, 25, -1],
              }"
            ></v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import gql from "graphql-tag";
import get from "lodash/get";
import groupBy from "lodash/groupBy";
import { mapGetters } from "vuex";
import InventoryByUser from "../charts/InventoryByUser";
// import RetainedByWeek from "../charts/RetainedByWeek.vue";
import { getPreLitAttorneysDb, getLitAttorneysDb } from "../util";

export default {
  components: {
    InventoryByUser,
    // RetainedByWeek
  },
  data() {
    return {
      viewMode: "chart",
      rankViewMode: "chart",
      showActiveOnly: false,
    };
  },
  apollo: {
    organization: {
      query: gql`
        query OrgHomeInventoryCharts($id: ID!) {
          organization(id: $id) {
            id
            name
            departments {
              id
              name
              users {
                staffCode
              }
            }
            inventoryByAttorney {
              staff
              totalIntakeCases
              totalTreatingCases
              totalDemandPrepCases
              totalNegotiationCases
              totalSettledCases
              totalUimCases
              totalReferredOutCases
              totalLitigationCases
              totalClosedCases
              totalNoStatusCases
            }
          }
        }
      `,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
    totalCasesByRankByAttorney: {
      query: gql`
        query OrgCasesByRankByAttorney {
          totalCasesByRankByAttorney {
            totalCases
            attorney
            totalAplusplusCases
            totalAplusCases
            totalACases
            totalBCases
            totalCCases
            totalDCases
            totalUnrankedCases
            totalNoRankCases
          }
        }
      `,
    },
    totalCasesByRank: {
      query: gql`
        query OrgCasesByRank {
          totalCasesByRank {
            totalAplusplusCases
            totalAplusCases
            totalACases
            totalBCases
            totalCCases
            totalDCases
            totalUnrankedCases
            totalNoRankCases
          }
        }
      `,
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
    inventoryByPreLitAttorney() {
      if (!this.organization) return {};
      let iba = this.organization.inventoryByAttorney;
      let attorneys = getPreLitAttorneysDb(this.organization);
      let series = this.buildFilteredInventorySeries(iba, attorneys);
      return {
        xAxis: {
          categories: attorneys,
        },
        series,
        plotOptions: {
          column: {
            stacking: "normal",
            reversedStacks: false,
          },
        },
      };
    },
    inventoryByLitAttorney() {
      if (!this.organization) return {};
      let iba = this.organization.inventoryByAttorney;
      let attorneys = getLitAttorneysDb(this.organization);
      let series = this.buildFilteredInventorySeries(iba, attorneys);
      return {
        xAxis: {
          categories: attorneys,
        },
        series,
        plotOptions: {
          column: {
            stacking: "normal",
            reversedStacks: false,
          },
        },
      };
    },
    casesByRankByPreLitAttorney() {
      if (!this.totalCasesByRankByAttorney || !this.organization) return {};
      let attorneys = getPreLitAttorneysDb(this.organization);
      let filteredData = this.totalCasesByRankByAttorney;

      // Skip filter for rank data for now, as it's not clear how to apply the active filter here
      // We'd need to know which rank categories to exclude

      let groupedByAttorney = groupBy(filteredData, (d) => {
        return d.attorney;
      });
      let series = [
        { name: "A++", color: "#03A9F4", value: "totalAplusplusCases" },
        { name: "A+", color: "#2196F3", value: "totalAplusCases" },
        { name: "A", color: "#3F51B5", value: "totalACases" },
        { name: "B", color: "#673AB7", value: "totalBCases" },
        { name: "C", color: "#9C27B0", value: "totalCCases" },
        { name: "D", color: "#E91E63", value: "totalDCases" },
        { name: "No Rank", color: "#F44336", value: "totalNoRankCases" },
      ].map((s) => {
        return {
          name: s.name,
          color: s.color,
          data: attorneys.reduce((prev, cur) => {
            if (!groupedByAttorney[cur]) {
              return prev.concat(0);
            } else {
              return prev.concat(groupedByAttorney[cur][0][s.value]);
            }
          }, []),
        };
      });

      return {
        xAxis: {
          categories: attorneys,
        },
        series,
      };
    },
    casesByRankByLitAttorney() {
      if (!this.totalCasesByRankByAttorney || !this.organization) return {};
      let attorneys = getLitAttorneysDb(this.organization);
      let filteredData = this.totalCasesByRankByAttorney;

      // Skip filter for rank data for now, as it's not clear how to apply the active filter here

      let groupedByAttorney = groupBy(filteredData, (d) => {
        return d.attorney;
      });
      let series = [
        { name: "A++", color: "#03A9F4", value: "totalAplusplusCases" },
        { name: "A+", color: "#2196F3", value: "totalAplusCases" },
        { name: "A", color: "#3F51B5", value: "totalACases" },
        { name: "B", color: "#673AB7", value: "totalBCases" },
        { name: "C", color: "#9C27B0", value: "totalCCases" },
        { name: "D", color: "#E91E63", value: "totalDCases" },
        { name: "No Rank", color: "#F44336", value: "totalNoRankCases" },
      ].map((s) => {
        return {
          name: s.name,
          color: s.color,
          data: attorneys.reduce((prev, cur) => {
            if (!groupedByAttorney[cur]) {
              return prev.concat(0);
            } else {
              return prev.concat(groupedByAttorney[cur][0][s.value]);
            }
          }, []),
        };
      });
      return {
        xAxis: {
          categories: attorneys,
        },
        series,
      };
    },
    casesByRank() {
      if (!this.organization || !this.totalCasesByRank) return {};
      let totalCases = 0;
      Object.values(this.totalCasesByRank[0]).forEach((val) => {
        if (Number.isInteger(val)) {
          totalCases += val;
        }
      });
      return {
        chart: {
          type: "bar",
          height: 100,
        },
        title: {
          text: "",
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          visible: false,
          labels: {
            enabled: false,
          },
        },
        yAxis: {
          min: 0,
          visible: false,
          title: {
            text: "",
          },
          labels: {
            enabled: false,
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            stacking: "normal",
          },
          bar: {
            dataLabels: {
              enabled: true,
              formatter: function() {
                return `${this.y} (${((this.y / totalCases) * 100).toFixed(
                  2
                )}%)`;
              },
            },
          },
        },
        tooltip: {
          headerFormat: "",
        },
        series: [
          {
            name: "A++ (> $1M)",
            color: "#03A9F4",
            data: [this.totalCasesByRank[0].totalAplusplusCases],
          },
          {
            name: "A+ ($251k - $1M)",
            color: "#2196F3",
            data: [this.totalCasesByRank[0].totalAplusCases],
          },
          {
            name: "A ($101k - $250k)",
            color: "#3F51B5",
            data: [this.totalCasesByRank[0].totalACases],
          },
          {
            name: "B ($51k - $100k)",
            color: "#673AB7",
            data: [this.totalCasesByRank[0].totalBCases],
          },
          {
            name: "C ($15k - $50k)",
            color: "#9C27B0",
            data: [this.totalCasesByRank[0].totalCCases],
          },
          {
            name: "D (<$15k)",
            color: "#E91E63",
            data: [this.totalCasesByRank[0].totalDCases],
          },
          {
            name: "Unrated - Default",
            color: "#F44336",
            data: [this.totalCasesByRank[0].totalUnrankedCases],
          },
          {
            name: "No Rank",
            color: "#FF9800",
            data: [this.totalCasesByRank[0].totalNoRankCases],
          },
        ],
      };
    },
  },
  methods: {
    getData(obj, field) {
      return get(obj, field);
    },

    buildFilteredInventorySeries(data, categories) {
      // First group by attorney
      let groupedByAttorney = groupBy(data, (d) => {
        return d.staff;
      });

      // Define which series to include in the chart, from bottom to top
      // With reversedStacks: false, first item in the array appears at the bottom
      // Color scheme: use a progression that follows the case lifecycle
      let seriesDefinitions = [
        { name: "No Status", value: "totalNoStatusCases", color: "#9E9E9E" }, // Gray for unclassified
        { name: "Intake", value: "totalIntakeCases", color: "#2196F3" }, // Blue for start
        { name: "Treating", value: "totalTreatingCases", color: "#4CAF50" }, // Green for treatment phase
        {
          name: "Demand Prep",
          value: "totalDemandPrepCases",
          color: "#FFC107",
        }, // Amber for demand prep
        {
          name: "Negotiation",
          value: "totalNegotiationCases",
          color: "#FF9800",
        }, // Orange for negotiation
        { name: "Litigation", value: "totalLitigationCases", color: "#F44336" }, // Red for litigation
        { name: "UIM", value: "totalUimCases", color: "#9C27B0" }, // Purple for UIM
      ];

      // Add inactive series only if showActiveOnly is false
      if (!this.showActiveOnly) {
        seriesDefinitions = seriesDefinitions.concat([
          {
            name: "Referred Out",
            value: "totalReferredOutCases",
            color: "#795548",
          }, // Brown for referred out
          { name: "Closed", value: "totalClosedCases", color: "#607D8B" }, // Blue-gray for closed
          { name: "Settled", value: "totalSettledCases", color: "#009688" }, // Teal for settlement (success!)
        ]);
      }

      // Build the series data
      let series = seriesDefinitions.map((s) => {
        return {
          name: s.name,
          color: s.color,
          data: categories.reduce((prev, cur) => {
            if (!groupedByAttorney[cur]) {
              return prev.concat(0);
            } else {
              return prev.concat(groupedByAttorney[cur][0][s.value]);
            }
          }, []),
        };
      });

      return series.reverse();
    },

    getTableHeaders(chartOptions) {
      if (!chartOptions.xAxis || !chartOptions.series) {
        return [];
      }

      // Create headers for the table
      let headers = [{ text: "Attorney", value: "attorney", sortable: true }];

      // Add column for each case type
      chartOptions.series.forEach((series) => {
        headers.push({
          text: series.name,
          value: series.name.toLowerCase().replace(/\s+/g, "_"),
          sortable: true,
        });
      });

      // Add total column
      headers.push({ text: "Total", value: "total", sortable: true });

      return headers;
    },

    getRankTableHeaders(chartOptions) {
      if (!chartOptions.xAxis || !chartOptions.series) {
        return [];
      }

      // Create headers for the table
      let headers = [{ text: "Attorney", value: "attorney", sortable: true }];

      // Add column for each rank
      chartOptions.series.forEach((series) => {
        headers.push({
          text: series.name,
          value: series.name
            .toLowerCase()
            .replace(/\s+/g, "_")
            .replace(/\+/g, "plus"),
          sortable: true,
        });
      });

      // Add total column
      headers.push({ text: "Total", value: "total", sortable: true });

      return headers;
    },

    getTableItems(chartOptions) {
      if (!chartOptions.xAxis || !chartOptions.series) {
        return [];
      }

      const categories = chartOptions.xAxis.categories;
      const series = chartOptions.series;

      // Create table items
      return categories.map((attorney, index) => {
        // Build base item
        let item = {
          attorney,
          total: 0,
        };

        // Add data for each series
        series.forEach((s) => {
          const key = s.name.toLowerCase().replace(/\s+/g, "_");
          const value = s.data[index] || 0;
          item[key] = value;
          item.total += value;
        });

        return item;
      });
    },

    getRankTableItems(chartOptions) {
      if (!chartOptions.xAxis || !chartOptions.series) {
        return [];
      }

      const categories = chartOptions.xAxis.categories;
      const series = chartOptions.series;

      // Create table items
      return categories.map((attorney, index) => {
        // Build base item
        let item = {
          attorney,
          total: 0,
        };

        // Add data for each series
        series.forEach((s) => {
          const key = s.name
            .toLowerCase()
            .replace(/\s+/g, "_")
            .replace(/\+/g, "plus");
          const value = s.data[index] || 0;
          item[key] = value;
          item.total += value;
        });

        return item;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.chart-vis {
  min-height: 250px;
}
</style>
