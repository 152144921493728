var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"sm":"12","md":"8"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v("Pre-lit Inventory")]),_c('v-spacer'),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-switch',{staticClass:"mt-0 pt-0",attrs:{"label":"Active Only","dense":"","hide-details":""},model:{value:(_vm.showActiveOnly),callback:function ($$v) {_vm.showActiveOnly=$$v},expression:"showActiveOnly"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-btn-toggle',{staticClass:"mt-0 pt-0",attrs:{"mandatory":"","dense":""},model:{value:(_vm.viewMode),callback:function ($$v) {_vm.viewMode=$$v},expression:"viewMode"}},[_c('v-btn',{attrs:{"small":"","value":"chart","title":"Chart View"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chart-bar")])],1),_c('v-btn',{attrs:{"small":"","value":"table","title":"Table View"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-table")])],1)],1)],1),(_vm.viewMode === 'chart')?_c('div',{staticClass:"chart-vis"},[_c('inventory-by-user',{attrs:{"options":_vm.inventoryByPreLitAttorney}})],1):_c('div',[_c('v-data-table',{attrs:{"headers":_vm.getTableHeaders(_vm.inventoryByPreLitAttorney),"items":_vm.getTableItems(_vm.inventoryByPreLitAttorney),"sort-by":['total'],"sort-desc":[true],"dense":"","footer-props":{
              'items-per-page-options': [10, 25, -1],
            }}})],1)],1)],1),_c('v-col',{attrs:{"sm":"12","md":"4"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v("Lit Inventory")]),_c('v-spacer'),_c('v-switch',{staticClass:"mt-0 pt-0",attrs:{"label":"Active Only","dense":"","hide-details":""},model:{value:(_vm.showActiveOnly),callback:function ($$v) {_vm.showActiveOnly=$$v},expression:"showActiveOnly"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-btn-toggle',{staticClass:"mt-0 pt-0",attrs:{"mandatory":"","dense":""},model:{value:(_vm.viewMode),callback:function ($$v) {_vm.viewMode=$$v},expression:"viewMode"}},[_c('v-btn',{attrs:{"small":"","value":"chart","title":"Chart View"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chart-bar")])],1),_c('v-btn',{attrs:{"small":"","value":"table","title":"Table View"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-table")])],1)],1)],1),(_vm.viewMode === 'chart')?_c('div',{staticClass:"chart-vis"},[_c('inventory-by-user',{attrs:{"options":_vm.inventoryByLitAttorney}})],1):_c('div',[_c('v-data-table',{attrs:{"headers":_vm.getTableHeaders(_vm.inventoryByLitAttorney),"items":_vm.getTableItems(_vm.inventoryByLitAttorney),"sort-by":['total'],"sort-desc":[true],"dense":"","footer-props":{
              'items-per-page-options': [10, 25, -1],
            }}})],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"12","md":"8"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v("Pre-Lit Inventory Ranks")]),_c('v-spacer'),_c('v-switch',{staticClass:"mt-0 pt-0",attrs:{"label":"Active Only","dense":"","hide-details":""},model:{value:(_vm.showActiveOnly),callback:function ($$v) {_vm.showActiveOnly=$$v},expression:"showActiveOnly"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-btn-toggle',{staticClass:"mt-0 pt-0",attrs:{"mandatory":"","dense":""},model:{value:(_vm.rankViewMode),callback:function ($$v) {_vm.rankViewMode=$$v},expression:"rankViewMode"}},[_c('v-btn',{attrs:{"small":"","value":"chart","title":"Chart View"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chart-bar")])],1),_c('v-btn',{attrs:{"small":"","value":"table","title":"Table View"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-table")])],1)],1)],1),(_vm.rankViewMode === 'chart')?_c('div',[_c('highcharts',{ref:"rankChart",attrs:{"options":_vm.casesByRank}})],1):_vm._e(),(_vm.rankViewMode === 'chart')?_c('div',{staticClass:"chart-vis"},[_c('inventory-by-user',{attrs:{"options":_vm.casesByRankByPreLitAttorney}})],1):_c('div',[_c('v-data-table',{attrs:{"headers":_vm.getRankTableHeaders(_vm.casesByRankByPreLitAttorney),"items":_vm.getRankTableItems(_vm.casesByRankByPreLitAttorney),"sort-by":['total'],"sort-desc":[true],"dense":"","footer-props":{
              'items-per-page-options': [10, 25, -1],
            }}})],1)],1)],1),_c('v-col',{attrs:{"sm":"12","md":"4"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v("Lit Inventory Ranks")]),_c('v-spacer'),_c('v-switch',{staticClass:"mt-0 pt-0",attrs:{"label":"Active Only","dense":"","hide-details":""},model:{value:(_vm.showActiveOnly),callback:function ($$v) {_vm.showActiveOnly=$$v},expression:"showActiveOnly"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-btn-toggle',{staticClass:"mt-0 pt-0",attrs:{"mandatory":"","dense":""},model:{value:(_vm.rankViewMode),callback:function ($$v) {_vm.rankViewMode=$$v},expression:"rankViewMode"}},[_c('v-btn',{attrs:{"small":"","value":"chart","title":"Chart View"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chart-bar")])],1),_c('v-btn',{attrs:{"small":"","value":"table","title":"Table View"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-table")])],1)],1)],1),(_vm.rankViewMode === 'chart')?_c('div',{staticClass:"chart-vis"},[_c('inventory-by-user',{attrs:{"options":_vm.casesByRankByLitAttorney}})],1):_c('div',[_c('v-data-table',{attrs:{"headers":_vm.getRankTableHeaders(_vm.casesByRankByLitAttorney),"items":_vm.getRankTableItems(_vm.casesByRankByLitAttorney),"sort-by":['total'],"sort-desc":[true],"dense":"","footer-props":{
              'items-per-page-options': [10, 25, -1],
            }}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }